/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const { getBrowserLanguage } = require("ptz-i18n")
const { navigate } = require("gatsby")
const lang = getLang()
exports.onInitialClientRender = () => {
  navigate(`/${lang}`)
}

function getLang() {
  let lang = getBrowserLanguage()
  lang = lang && lang.length ? lang.split("-")[0] : "en"
  switch (lang) {
    case "en":
      return ""
    case "zh":
      return "zh/"
    case "ko":
      return "ko/"
    default:
      return ""
  }
}
